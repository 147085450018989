<template>
  <div class="animated fadeIn faster flex flex-col items-center">
    <div v-if="data.settingWidget.durationSelect && !showDayTimeSelect" class="mb-1 w-10/12">
      <div class="relative rounded-md shadow-sm">
        <select
          class="
            transition
            form-select
            block
            w-full
            cursor-pointer
            duration-150
            ease-in-out
            sm:text-sm sm:leading-5
          "
          v-model="duration"
          @change="setDuration"
        >
          <option selected disabled :value="null">{{ $t('step.time.i_stay_default') }}</option>
          <option
            v-for="durationValue in durationValues"
            :key="'duration-value-' + durationValue.id"
            :value="durationValue.id"
          >
            {{ durationValue.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="min-width w-10/12" style="min-height: 8rem" v-if="showDayTimeSelect">
      <alert-info
        v-if="
          items.breakfast.length == 0 &&
          items.dinner.length == 0 &&
          items.lunch.length == 0 &&
          !$parent.loading
        "
        class="mt-2"
        text="Keine Uhrzeiten verfügbar"
      />
      <day-time
        data-cypress="daytime-breakfast"
        :items="items.breakfast"
        @selected="clickedBreakfast"
        :title="$t('time.breakfast')"
        v-if="showBreakfast"
      />
      <day-time
        data-cypress="daytime-lunch"
        :items="items.lunch"
        @selected="clickedLunch"
        :title="$t('time.lunch')"
        v-if="showLunch"
      />
      <day-time
        data-cypress="daytime-dinner"
        :items="items.dinner"
        @selected="clickedDinner"
        :title="$t('time.dinner')"
        v-if="showDinner"
      />
    </div>
    <time-listing
      :date="date"
      :data="data"
      :model="model"
      :key="'time-listing-' + duration"
      :hour-before="hourBefore"
      v-if="!showDayTimeSelect"
      @set-time="setTime"
      :items="choosedTimes"
    />
    <div
      v-if="!showDayTimeSelect"
      @click="showDateTimeSelect"
      class="animated fadeIn faster cursor-pointer p-2 text-center text-sm text-gray-600 hover:text-primary"
    >
      {{ $t('time.switch') }}
    </div>
  </div>
</template>

<script>
import DayTime from './DayTime'
import TimeListing from './Listing'
import AlertInfo from '../../components/Alert/Info'
import { duration } from '../../utils/duration'

export default {
  name: 'TimeWrapper',
  components: { AlertInfo, TimeListing, DayTime },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    initDuration: {
      type: String,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    model: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    hourBefore: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      choosedTimes: [],
      selectedDaytime: null,
      duration: this.initDuration,
      durationValues: duration(),
      showDayTimeSelect: true
    }
  },
  computed: {
    showBreakfast() {
      return this.items.breakfast.length != 0
    },
    showLunch() {
      return this.items.lunch.length != 0
    },
    showDinner() {
      return this.items.dinner.length != 0
    }
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler() {
        if (this.items) {
          if (
            this.items.breakfast.length != 0 &&
            this.items.lunch.length == 0 &&
            this.items.dinner.length == 0
          ) {
            this.clickedBreakfast()
          }

          if (
            this.items.breakfast.length == 0 &&
            this.items.lunch.length != 0 &&
            this.items.dinner.length == 0
          ) {
            this.clickedLunch()
          }

          if (
            this.items.breakfast.length == 0 &&
            this.items.lunch.length == 0 &&
            this.items.dinner.length != 0
          ) {
            this.clickedDinner()
          }

          if (this.selectedDaytime) {
            this.choosedTimes = this.items[this.selectedDaytime]
          }
        }
      }
    }
  },
  methods: {
    setDuration() {
      this.$emit('set-duration', this.duration)
    },
    setTime(time) {
      this.$emit('set-time', time)
    },
    showDateTimeSelect() {
      this.showDayTimeSelect = true
    },
    clickedBreakfast() {
      this.showDayTimeSelect = false
      this.selectedDaytime = 'breakfast'
      this.choosedTimes = this.items.breakfast
    },
    clickedLunch() {
      this.showDayTimeSelect = false
      this.selectedDaytime = 'lunch'
      this.choosedTimes = this.items.lunch
    },
    clickedDinner() {
      this.showDayTimeSelect = false
      this.selectedDaytime = 'dinner'
      this.choosedTimes = this.items.dinner
    }
  }
}
</script>
