<template>
  <div
    class="
      rounded
      border
      items-between
      mb-3
      flex
      cursor-pointer
      flex-col
      justify-center
      border-gray-200
      bg-white
    "
    :class="{ 'opacity-50': disabled }"
  >
    <div @click="select" class="flex items-center p-3 hover:bg-gray-100">
      <div class="w-full flex-1">
        <div class="text-xs font-bold">{{ menu.name }}</div>
        <div class="text-xs text-gray-600" v-if="menu.desc" v-html="description"></div>
      </div>
      <div
        v-if="!disabled && menu.price > 0"
        class="rounded shadow ml-3 w-24 bg-primary px-2 py-1 text-center text-xs font-bold text-white"
      >
        {{ menu.price | formatPrice }} p.P.
      </div>
      <div
        v-if="!disabled && menu.price == 0"
        class="rounded shadow ml-3 w-24 bg-primary px-2 py-1 text-center text-xs font-bold text-white"
      >
        {{ $t('price_in_card') }}
      </div>
      <div v-if="disabled" class="ml-3 w-24 text-center text-xs font-bold text-gray-600">
        {{ $t('from') }} {{ menu.min_persons }} {{ $tc('persons', 2) }}
      </div>
    </div>
    <a
      v-if="menu.menu_link"
      :href="menu.menu_link"
      target="_blank"
      class="border-t flex w-full items-center border-gray-200 p-2 text-xs text-gray-600 hover:bg-gray-100"
    >
      <svg viewBox="0 0 20 20" fill="currentColor" class="external-link mr-2 h-4 w-4 text-gray-400">
        <path
          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
        ></path>
        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
      </svg>
      {{ $t('see_menu') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'MenuSpecialEntry',
  props: {
    menu: {
      type: Object,
      default: () => {}
    },
    reservation: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    select() {
      if (!this.disabled) {
        this.$emit('select', this.menu.id)
      }
    }
  },
  computed: {
    disabled() {
      return this.persons < this.menu.min_persons
    },
    persons() {
      return this.reservation.children + this.reservation.adults
    },
    description() {
      if (this.menu.desc) {
        return this.menu.desc.replace(/\n/g, '<br />')
      }

      return null
    }
  }
}
</script>
