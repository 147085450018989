<template>
  <div class="mb-4 w-full">
    <div class="relative mt-1 rounded-md shadow-sm">
      <select
        v-model="value"
        class="
          transition
          form-select
          block
          w-full
          cursor-pointer
          duration-150
          ease-in-out
          sm:text-sm sm:leading-5
        "
        :disabled="schema.disabled"
        :name="schema.model"
      >
        <option
          v-for="value in items"
          :key="value.id + getRandomString()"
          :value="value.id"
          :disabled="value.disabled ? true : false"
          v-text="value.name"
        />
      </select>
    </div>
    <p v-if="fieldErrors" class="mt-1 text-sm text-red-600" v-text="fieldErrors" />
    <p
      v-if="schema.desc"
      style="white-space: pre-line"
      class="mt-1 text-xs text-gray-500"
      v-text="schema.desc"
    />
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { isObject, isNil, find } from 'lodash'

export default {
  name: 'SelectInput',
  mixins: [abstractField],
  computed: {
    selectOptions() {
      return this.schema.selectOptions || {}
    },

    fieldErrors() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$page && this.$page.errors.hasOwnProperty(this.schema.model)) {
        return this.$page.errors[this.schema.model][0]
      }

      return false
    },
    items() {
      let values = this.schema.values
      if (typeof values == 'function') {
        return this.groupValues(values.apply(this, [this.model, this.schema]))
      } else return this.groupValues(values)
    }
  },
  methods: {
    getRandomString() {
      return Math.random().toString(36).substring(10)
    },
    formatValueToField(value) {
      if (isNil(value)) {
        return null
      }
      return value
    },
    groupValues(values) {
      let array = []
      let arrayElement = {}
      values.forEach((item) => {
        arrayElement = null
        if (item.group && isObject(item)) {
          // There is in a group.
          // Find element with this group.
          arrayElement = find(array, (i) => i.group === item.group)
          if (arrayElement) {
            // There is such a group.
            arrayElement.ops.push({
              id: item.id,
              name: item.name
            })
          } else {
            // There is not such a group.
            // Initialising.
            arrayElement = {
              group: '',
              ops: []
            }
            // Set group.
            arrayElement.group = item.group
            // Set Group element.
            arrayElement.ops.push({
              id: item.id,
              name: item.name
            })
            // Add array.
            array.push(arrayElement)
          }
        } else {
          // There is not in a group.
          array.push(item)
        }
      })
      // With Groups.
      return array
    },
    getGroupName(item) {
      if (item && item.group) {
        return item.group
      }
      throw 'Group name is missing! https://icebob.gitbooks.io/vueformgenerator/content/fields/select.html#select-field-with-object-items'
    },
    getItemValue(item) {
      if (isObject(item)) {
        if (
          typeof this.schema['selectOptions'] !== 'undefined' &&
          typeof this.schema['selectOptions']['value'] !== 'undefined'
        ) {
          return item[this.schema.selectOptions.value]
        } else {
          // Use 'id' instead of 'value' cause of backward compatibility
          if (typeof item['id'] !== 'undefined') {
            return item.id
          } else {
            throw '`id` is not defined. If you want to use another key name, add a `value` property under `selectOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/select.html#select-field-with-object-items'
          }
        }
      } else {
        return item
      }
    },
    getItemName(item) {
      if (isObject(item)) {
        if (
          typeof this.schema['selectOptions'] !== 'undefined' &&
          typeof this.schema['selectOptions']['name'] !== 'undefined'
        ) {
          return item[this.schema.selectOptions.name]
        } else {
          if (typeof item['name'] !== 'undefined') {
            return item.name
          } else {
            throw '`name` is not defined. If you want to use another key name, add a `name` property under `selectOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/select.html#select-field-with-object-items'
          }
        }
      } else {
        return item
      }
    }
  }
}
</script>
