<template>
  <div class="mb-4 w-full">
    <div class="relative mt-1 rounded-md shadow-sm">
      <vue-tel-input
        :key="'country-code-' + defaultCountry"
        :auto-default-country="false"
        :default-country="defaultCountry"
        :inputOptions="{
          placeholder: schema.placeholder
        }"
        v-model="value"
        :valid-characters-only="true"
        mode="international"
        :name="schema.model"
        :preferred-countries="['at', 'de', 'gb', 'it']"
        @input="input"
      />
    </div>
    <p v-if="fieldErrors" class="mt-1 text-sm text-red-600" v-text="fieldErrors" />
    <p v-if="schema.help" class="mt-1 text-sm text-gray-500" v-text="schema.help" />
    <p v-if="!schema.required" class="mt-1 text-sm text-gray-500" v-text="$t('form.phone_is_not_required')" />
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { VueTelInput } from 'vue-tel-input/dist/vue-tel-input.umd.min'
//import axios from 'axios'

export default {
  name: 'PhoneInput',
  components: {
    'vue-tel-input': VueTelInput
  },
  mixins: [abstractField],
  data() {
    return {
      isValid: null,
      defaultCountry: 'at'
    }
  },
  computed: {
    fieldErrors() {
      /*if (this.$page && this.$page.errors.hasOwnProperty(this.schema.model)) {
        return this.$page.errors[this.schema.model][0];
      }*/

      return false
    }
  },
  mounted() {
    /*axios.get('https://app.tably./api/get-ip').then((payload) => {
      this.defaultCountry = payload.data.country
    })*/
  },
  methods: {
    input(input, object) {
      this.isValid = object.valid
    }
  }
}
</script>
