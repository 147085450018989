<template>
  <div class="mb-4">
    <widget-header :title="$t('step.menu.title')" :sub-title="$t('step.menu.desc')" />
    <alert-warning
      class="relative z-20 hidden"
      v-if="preOrderIsRequired"
      :text="$t('preorder_is_required')"
    ></alert-warning>
    <menu-pre-order
      v-if="showPreOrder"
      @toggle-offer="toggleOffer"
      @reset="reset"
      @menu-persons-changed="menuPersonsChanged"
      @show="togglePreOrder"
      @select-dish="selectDish"
      :model="model"
      :data="data"
      :pre-order-is-required="preOrderIsRequired"
      :nothing-multiple="menuData.nothingMultiple"
      :has-child-menu="menuData.hasChildren"
      :menu-data="menuData.preOrders"
    />
    <div v-if="menuData.onlyMenu == false">
      <menu-category
        data-cypress="preorder-menu"
        v-if="!showPreOrder && !showSpecialMenu && menuData.preOrders.length > 0"
        @select="togglePreOrder"
        :title="$t('preorder')"
        :text="data.widgetMessage.menu"
      />
      <menu-category
        data-cypress="special-menu"
        v-if="!showPreOrder && !showSpecialMenu && menuData.specialMenu.length > 0"
        @select="toggleSpecial"
        :title="$t('menu')"
      />
      <menu-category
        data-cypress="alacarte-menu"
        v-if="!showPreOrder && !showSpecialMenu"
        @select="selectStandardMenu"
        title="à la carte"
        :text="data.widgetMessage.alacarte"
      />
    </div>
    <menu-special-entry
      @select="selectSpecialMenu"
      v-for="menu in menuData.specialMenu"
      :reservation="model.reservation"
      :menu="menu"
      :key="menu.id"
    />
  </div>
</template>

<script>
import WidgetHeader from '../components/Header/Index'
import MenuCategory from '../components/Menu/Category'
import MenuPreOrder from '../components/Menu/PreOrder/Index'
import MenuSpecialEntry from '../components/Menu/SpecialMenu/Entry'
import AlertWarning from '../components/Alert/Warning'

export default {
  name: 'MenuStep',
  data() {
    return {
      date: null,
      showPreOrder: false,
      showSpecialMenu: false
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Object,
      default: () => {}
    },
    menuData: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    '$parent.active'() {
      if (this.$parent.active) {
        this.$emit('fetch-menu')
        this.$emit('set-visibility-btn', true)
        if (this.model.reservation.preOrders.length == 0 && this.preOrderIsRequired) {
          this.$emit('set-visibility-btn', false)
        }
        if (this.model.reservation.preOrders.length > 0 && this.preOrderIsRequired) {
          this.$emit('set-visibility-btn', true)
        }
      }
    },
    menuData() {
      // show next button not when special menu is required
      if (this.$parent.active && this.menuData.specialMenu.length > 0) {
        this.$emit('set-visibility-btn', false)
      }
    },
    'model.reservation.time'() {
      this.reset()
    },
    'model.reservation.date'() {
      this.reset()
    },
    'model.reservation.preOrders'() {
      if (this.$parent.active) {
        if (this.model.reservation.preOrders.length == 0 && this.preOrderIsRequired) {
          this.$emit('set-visibility-btn', false)
        }
        if (this.model.reservation.preOrders.length > 0 && this.preOrderIsRequired) {
          this.$emit('set-visibility-btn', true)
        }
      }
    }
  },
  components: {
    AlertWarning,
    MenuSpecialEntry,
    MenuPreOrder,
    MenuCategory,
    WidgetHeader
  },
  computed: {
    preOrderIsRequired() {
      if (this.data.settingReservation.preorder_required && this.menuData.specialMenu.length == 0) {
        const reservationTime = this.model.reservation.time
        const adults = this.model.reservation.adults
        const minPersons = this.data.settingReservation.preorder_required_persons

        //check if enough persons
        const enoughPersons = adults >= minPersons

        const requiredFrom = this.data.settingReservation.preorder_required_from
        const requiredTo = this.data.settingReservation.preorder_required_to

        if (enoughPersons && reservationTime >= requiredFrom && reservationTime <= requiredTo) {
          this.showPreOrder = true // eslint-disable-line
          this.showSpecialMenu = false // eslint-disable-line
          return true
        }
      }

      return false
    }
  },
  methods: {
    menuPersonsChanged(val) {
      if (this.$parent.active) {
        this.$root.$emit('reload-menu', val)
      }
      this.$emit('menu-persons-changed', val)
    },
    toggleOffer(val) {
      this.$emit('toggle-offer', val)
    },
    reset() {
      this.showPreOrder = false
      this.showSpecialMenu = false
      this.$emit('reset-menu')
    },
    selectStandardMenu() {
      this.$emit('select-standard')
    },
    selectSpecialMenu(id) {
      this.$emit('select-special', id)
    },
    togglePreOrder() {
      this.showPreOrder = true
      this.showSpecialMenu = false
    },
    toggleSpecial() {
      this.showPreOrder = false
      this.showSpecialMenu = true
    },
    selectDish(dish) {
      this.$emit('select-dish', dish)
    }
  }
}
</script>
