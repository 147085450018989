<template>
  <div class="border-l-4 border-blue-400 bg-blue-50 p-2 lg:p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <svg class="h-5 w-5 text-blue-400" fill="currentColor" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p class="text-sm leading-5 text-blue-600" v-html="text"></p>
        <p v-if="href" class="mt-3 text-sm leading-5 md:ml-6 md:mt-0">
          <inertia-link
            class="
              whitespace-no-wrap
              transition
              font-medium
              text-primary
              duration-150
              ease-in-out
              hover:text-primary
            "
            :href="href"
          >
            {{ btnText }}
            <i class="fas fa-arrow-right ml-1 text-xs"></i>
          </inertia-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertInfo',
  props: {
    text: {
      type: String,
      default: null
    },
    btnText: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    }
  }
}
</script>
