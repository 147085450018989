<template>
  <div class="mb-4 w-full">
    <div class="relative mt-1 rounded-md shadow-sm">
      <textarea
        v-model="value"
        :placeholder="schema.placeholder"
        :rows="schema.rows"
        :data-cypress="schema.cypress"
        :disabled="disabled"
        class="transition form-textarea block w-full duration-150 ease-in-out sm:text-sm sm:leading-5"
        :class="{
          'border-red-500': fieldErrors,
          'cursor-not-allowed bg-gray-100': disabled
        }"
        :maxlength="schema.max"
      />
    </div>
    <div v-if="!disabled" class="mt-1 text-xs text-gray-500">
      ({{ stringLength }} / {{ schema.maxLength }}) {{ $t('character') }}
    </div>
    <p v-if="fieldErrors" class="mt-2 text-sm text-red-600" v-text="fieldErrors" />
    <p v-if="schema.desc" class="mt-2 text-sm text-gray-500" v-text="schema.desc" />
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  mixins: [abstractField],
  computed: {
    stringLength() {
      if (this.value) {
        return this.value.length
      }

      return 0
    },
    fieldErrors() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$page && this.$page.errors && this.$page.errors.hasOwnProperty(this.schema.model)) {
        return this.$page.errors[this.schema.model][0]
      }

      return false
    }
  }
}
</script>
