<template>
  <div class="animated fadeIn mx-auto mb-4 h-full pb-8">
    <widget-header :title="$t('step.deposit.title')" />
    <div class="mt-6 text-center text-4xl text-purple-600">
      <i class="fad fa-credit-card"></i>
    </div>
    <div
      v-if="data.deposit.days_before > 0"
      class="mb-6 mt-6 px-3 text-center text-sm text-gray-600"
      v-html="
        $t('step.deposit.note', {
          daysBefore: data.deposit.days_before,
          amount: data.deposit.amount
        })
      "
    ></div>
    <div
      v-if="data.deposit.days_before == 0"
      class="mb-6 mt-6 px-3 text-center text-sm text-gray-600"
      v-html="
        $t('step.deposit.note_free_cancel', {
          daysBefore: data.deposit.days_before,
          amount: data.deposit.amount
        })
      "
    ></div>

    <stripe-checkout
      ref="sessionRef"
      mode="payment"
      :account="data.restaurant.stripe_account"
      :pk="data.restaurant.stripe"
      @loading="(v) => (loading = v)"
      :session-id="sessionId"
    />

    <div class="mt-4 text-center">
      <button
        type="button"
        @click="$refs.sessionRef.redirectToCheckout()"
        class="
          border
          focus:outline-none
          inline-flex
          items-center
          rounded-md
          border-transparent
          bg-primary
          px-3
          py-2
          text-sm
          font-medium
          leading-4
          text-white
          shadow-sm
          hover:bg-primary
        "
      >
        {{ $t('step.deposit.deposit_payment_data') }}
      </button>
    </div>
    <div class="mt-3 flex items-center justify-center text-center text-xl text-black opacity-75">
      <i class="fab fa-cc-visa mx-1"></i>
      <i class="fab fa-cc-mastercard mx-1"></i>
      <i class="fab fa-cc-jcb mx-1"></i>
      <i class="fab fa-cc-discover mx-1"></i>
      <i class="fab fa-cc-amex mx-1"></i>
      <i class="fab fa-cc-diners-club mx-1"></i>
      <img class="mx-1" style="height: 25px" src="https://img.icons8.com/ios-filled/50/000000/sepa.png" />
    </div>

    <div class="absolute bottom-0 left-0 right-0 p-4 text-center">
      <img class="mx-auto h-6" :src="data.brand" />
    </div>
  </div>
</template>

<script>
import WidgetHeader from '../components/Header/Index'
import { StripeCheckout } from '../vendor/@vue-stripe/vue-stripe/src/index.js'

export default {
  name: 'ReservationDeposit',
  components: {
    WidgetHeader,
    StripeCheckout
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    sessionId: {
      type: String,
      default: null
    }
  }
}
</script>
