<template>
  <div
    :data-cypress="!isPast && !disabled ? 'time-entry' : ''"
    @click="setTime"
    :class="{ 'cursor-not-allowed opacity-50': isPast || disabled }"
    class="
      border-b
      transition
      flex
      w-full
      cursor-pointer
      items-center
      justify-between
      border-gray-200
      px-3
      py-1.5
      leading-snug
      duration-500
      ease-in-out
      hover:bg-gray-100
    "
  >
    <div class="w-24 text-left">
      <div>{{ item.time }}</div>
      <div class="whitespace-no-wrap text-xs opacity-75" v-if="item.time_until">
        {{ item.time_until }}
      </div>
    </div>
    <div class="w-full pl-6 text-right text-xs">
      <span
        v-if="isPast"
        class="
          border
          inline-flex
          items-center
          rounded-md
          border-red-300
          bg-red-100
          px-3
          py-1
          text-xs
          font-medium
          text-red-800
        "
      >
        {{ $t('step.time.bookedOut') }}
      </span>
      <span
        v-else-if="disabled"
        class="
          border
          inline-flex
          items-center
          rounded-md
          border-red-300
          bg-red-100
          px-3
          py-1
          text-xs
          font-medium
          text-red-800
        "
      >
        {{ $t('step.time.bookedOut') }}
      </span>
      <span
        v-else-if="disabledBecauseLeadTime"
        v-html="$t('step.time.bookedOutLeadTime', { phone: data.restaurant.phone })"
      ></span>
      <span
        v-else
        class="
          border
          inline-flex
          items-center
          rounded-md
          border-green-300
          bg-green-100
          px-3
          py-1
          text-xs
          font-medium
          text-green-800
        "
      >
        {{ $t('step.time.available') }}
      </span>
    </div>
  </div>
</template>

<!--
  <button
        :disabled="disabled"
        :class="{ 'opacity-50 cursor-not-allowed': disabled }"
        type="button"
        @click="setTime"
        class="
          bg-primary
          leading-tight
          inline-flex
          w-full
          flex-col
          items-center
          px-4
          py-2
          border border-transparent
          text-sm
          font-medium
          rounded-md
          text-white
          focus:outline-none
        "
      >
        <div>{{ item.time }}</div>
        <div class="text-xs opacity-75 whitespace-no-wrap" v-if="item.time_until">
          {{ item.time_until }}
        </div>
        <small class="opacity-50 hidden" style="font-size: 10px">
          <span v-if="disabled">{{ $t('step.time.bookedOut') }}</span>
          <span v-if="!disabled">{{ $t('step.time.available') }}</span>
        </small>
      </button>-->

<script>
import moment from 'moment-timezone'

export default {
  name: 'TimeItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    date: {
      type: String,
      default: null
    },
    model: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    hourBefore: {
      type: Number,
      default: null
    }
  },
  methods: {
    setTime() {
      if (this.isPast || this.disabledBecauseLeadTime || this.disabled) {
        return
      }

      this.$emit('set-time', this.item)
    }
  },
  computed: {
    isPast() {
      const now = moment().tz('Europe/Vienna')
      const timeMoment = moment(this.item.time, 'HH:ii').tz('Europe/Vienna')

      if (this.isToday && now > timeMoment) {
        return true
      }

      return false
    },
    disabledBecauseLeadTime() {
      const now = moment().tz('Europe/Vienna').add(this.hourBefore, 'hours')

      const timeMoment = moment(this.item.time, 'HH:ii').tz('Europe/Vienna')

      if (this.isToday && now > timeMoment) {
        return true
      }

      return false
    },
    disabled() {
      return (
        this.item.freeSeats < this.model.reservation.adults + this.model.reservation.children ||
        this.item.disabled
      )
    },
    isToday() {
      const date = moment(this.date).format('YYYY-MM-DD')
      const today = moment().format('YYYY-MM-DD')

      if (date == today) {
        return true
      }

      return false
    }
  }
}
</script>
