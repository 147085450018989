<template>
  <div class="mb-4 w-full">
    <div class="relative mt-1 rounded-md shadow-sm">
      <input
        v-model="value"
        autocomplete="no"
        :disabled="disabled"
        :maxlength="schema.max"
        :name="schema.model"
        :placeholder="schema.placeholder"
        class="form-input block w-full text-sm sm:leading-5"
        :type="schema.inputType"
      />
      <p v-if="schema.help" class="mt-2 text-sm text-gray-500" v-text="schema.help" />
    </div>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  name: 'TextInput',
  mixins: [abstractField]
  /*  computed: {
          :class="{ 'border-red-500': fieldErrors }"

      <p
        v-if="fieldErrors"
        class="mt-2 text-sm text-red-600"
        v-text="fieldErrors"
      />

    fieldErrors() {
      if (this.errors.length > 0 && this.errors.hasOwnProperty(this.schema.model)) {
        return this.$parent.errors[this.schema.model][0];
      }

      return false;
    },
  },*/
}
</script>
