<template>
  <div class="mb-6">
    <widget-header :title="$t('step.persons.title')" :sub-title="$t('step.persons.desc')" />
    <alert-warning :text="data.widgetMessage.persons" />
    <alert-warning
      :text="
        $t('step.persons.limit', { limit: data.settingReservation.maxPersons, phone: data.restaurant.phone })
      "
      v-if="personLimitReached"
    />
    <div v-if="showChildren" class="min-width h-32">
      <person-select
        title="adults"
        :default="2"
        :min="1"
        @remove="removePersons"
        @add="addPersons"
        :limit-reached="personLimitReached"
      />
      <person-select
        title="children"
        :default="0"
        @remove="removeChildren"
        @add="addChildren"
        :limit-reached="personLimitReached"
      />
    </div>
    <div v-if="!showChildren" class="min-width h-24 pt-4">
      <person-select
        title="persons"
        :default="2"
        :min="1"
        @remove="removePersons"
        @add="addPersons"
        :limit-reached="personLimitReached"
      />
    </div>
  </div>
</template>

<script>
import WidgetHeader from '../components/Header/Index'
import PersonSelect from '../components/Person/Index'
import AlertWarning from '../components/Alert/Warning'

export default {
  name: 'TimePersons',
  data() {
    return {
      date: null,
      persons: 2,
      children: 0
    }
  },
  props: {
    showChildren: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    '$parent.active'() {
      if (this.$parent.active) {
        this.$emit('set-visibility-btn', true)
      }
    }
  },
  components: {
    AlertWarning,
    PersonSelect,
    WidgetHeader
  },
  computed: {
    personLimitReached() {
      return this.data.settingReservation.maxPersons <= this.children + this.persons
    }
  },
  methods: {
    removePersons() {
      this.persons--
      this.$emit('remove-persons')
    },
    addPersons() {
      if (!this.personLimitReached) {
        this.persons++
        this.$emit('add-persons')
      }
    },
    removeChildren() {
      this.children--
      this.$emit('remove-children')
    },
    addChildren() {
      if (!this.personLimitReached) {
        this.children++
        this.$emit('add-children')
      }
    }
  }
}
</script>
