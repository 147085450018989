<template>
  <div class="w-full" style="min-height: 8rem">
    <div
      class="border rounded scrolling-touch relative mx-auto w-10/12 overflow-auto rounded-md border-gray-200"
      style="max-height: 20rem; min-height: 20rem"
      @scroll="showScrollInfo = false"
      id="time-scrolling"
    >
      <time-item
        :hour-before="hourBefore"
        :date="date"
        :data="data"
        :model="model"
        @set-time="setTime"
        v-for="(item, key) in items"
        :key="key + item.time"
        :item="item"
      />
      <div
        v-if="showScrollInfo"
        class="
          animated
          fadeIn
          gradient-white
          absolute
          bottom-0
          left-0
          right-0
          flex
          items-center
          justify-center
          pb-3
          pt-5
          text-xs
          font-bold
          text-gray-700
        "
      >
        <svg
          class="mr-2 h-4"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M19 9l-7 7-7-7"></path>
        </svg>
        {{ $t('scroll_to_see_more') }}
      </div>
    </div>
  </div>
</template>

<script>
import TimeItem from './Item'

export default {
  name: 'TimeListing',
  components: { TimeItem },
  props: {
    items: {
      type: [Array, Object],
      default: () => []
    },
    date: {
      type: String,
      default: null
    },
    model: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    hourBefore: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      showScrollInfo: false
    }
  },
  mounted() {
    this.checkScrollHover()
  },
  methods: {
    checkScrollHover() {
      const element = document.getElementById('time-scrolling')

      if (element) {
        element.scrollTop = 0

        if (element.scrollHeight > element.clientHeight) {
          this.showScrollInfo = true
        }
      }
    },
    setTime(time) {
      this.$emit('set-time', time)
    }
  }
}
</script>
