<template>
  <span class="mb-2 inline-flex w-full rounded-md shadow-lg">
    <button
      type="button"
      @click="selected"
      class="
        border
        transition
        inline-flex
        w-full
        flex-col
        items-center
        justify-center
        rounded-md
        border-transparent
        bg-primary
        px-4
        py-2
        text-center text-sm
        font-medium
        text-white
        opacity-100
        duration-150
        ease-in-out
      "
    >
      {{ title }}
      <br />
      <small class="opacity-75">{{ first }} - {{ last }}</small>
    </button>
  </span>
</template>

<script>
export default {
  name: 'DayTime',
  props: {
    title: {
      type: String,
      default: null
    },
    items: {
      type: [Array, Object],
      default: () => []
    }
  },
  methods: {
    selected() {
      this.$emit('selected')
    }
  },
  computed: {
    last() {
      if (this.items.length > 0) {
        return this.items[this.items.length - 1].time
      }

      return null
    },
    first() {
      if (this.items.length > 0) {
        return this.items[0].time
      }

      return null
    }
  }
}
</script>
