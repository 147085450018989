<template>
  <canvas class="absolute inset-0 z-0" id="my-canvas"></canvas>
</template>

<script>
import ConfettiGenerator from 'confetti-js'

export default {
  name: 'AnimationConfetti',
  mounted() {
    const confettiElement = document.getElementById('my-canvas')
    const confettiSettings = {
      target: confettiElement,
      max: 40,
      props: ['square', 'triangle', 'line']
    }
    const confetti = new ConfettiGenerator(confettiSettings)
    confetti.render()
  }
}
</script>
