<template>
  <div v-if="text" class="relativ z-60 animated fadeIn faster -mt-5 mb-2 flex justify-center">
    <span
      class="
        rounded
        mt-1
        inline-flex
        items-center
        bg-gray-100
        px-3
        py-1
        text-xs
        font-medium
        leading-tight
        text-gray-800
      "
    >
      <i class="fad fa-info-circle mr-2"></i>
      <div v-html="text" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'AlertWarning',
  props: {
    text: {
      type: String,
      default: null
    }
  }
}
</script>
