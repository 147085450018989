<template>
  <div class="mx-auto mb-4 flex h-full max-w-sm flex-col items-center pt-8">
    <i class="fad fa-exclamation-circle pb-6 text-5xl text-yellow-400"></i>
    <div class="px-6 text-center text-gray-600">
      {{ $t('table_not_available') }}
    </div>
    <span class="mt-6 inline-flex rounded-md shadow-sm">
      <button
        type="button"
        @click="reloadPage"
        class="
          border
          focus:outline-none
          transition
          inline-flex
          items-center
          rounded-md
          border-transparent
          bg-primary
          px-3
          py-2
          text-sm
          font-medium
          leading-4
          text-white
          duration-150
          ease-in-out
          hover:bg-primary
          focus:border-purple-700 focus:shadow-outline-indigo
          active:bg-purple-700
        "
      >
        {{ $t('btn.new_reservation') }}
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'NoTableAvailable',
  methods: {
    reloadPage() {
      location.reload(true)
    }
  }
}
</script>
