<template>
  <div v-if="visible">
    <div class="cursor-pointer overflow-hidden bg-white" :class="{ 'absolute inset-0 z-10': show }">
      <div
        data-cypress="category-entry"
        class="flex items-center justify-between bg-white px-0 py-2"
        :class="{ 'border-b border-dashed border-gray-200': !show }"
        style="min-height: 3rem"
        @click="toggle"
      >
        <div class="w-full pr-5">
          <div class="pb-1 pt-1 text-sm font-bold leading-none" v-text="category.name" />
          <div class="text-xs leading-normal text-gray-600">
            <div v-for="dish in selectDishes" :key="dish.id">{{ dish.amount }}x {{ dish.title }}</div>
          </div>
        </div>
        <div class="ml-3 h-6 w-6 flex-auto" style="min-width: 1.5rem; max-width: 1.5rem">
          <svg
            :class="{ 'rotate-90': show }"
            class="transition rotate-0 transform opacity-50 duration-150 ease-in-out"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M9 5l7 7-7 7"></path>
          </svg>
        </div>
      </div>
      <div v-if="show" class="relative z-20 bg-white">
        <div
          :id="'category-' + category.id + '-' + renderIndex"
          class="border rounded overflow-auto border-gray-200"
          style="max-height: calc(100% - 3rem)"
          @scroll="showScrollInfo = false"
        >
          <menu-pre-order-dish
            v-for="(dish, key) in filteredDishes"
            :key="'dish-id-' + dish.id"
            :last="key + 1 != filteredDishes.length"
            :has-child-menu="hasChildMenu"
            :category="category"
            :dish="dish"
            :model="model"
            @select-dish="selectDish"
          />
        </div>
        <div
          v-if="showScrollInfo"
          class="
            animated
            fadeIn
            gradient-white
            absolute
            bottom-0
            left-0
            right-0
            flex
            items-center
            justify-center
            pb-2
            pt-4
            text-xs
            font-bold
            text-gray-700
          "
        >
          <svg
            class="mr-2 h-4"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 9l-7 7-7-7"></path>
          </svg>
          {{ $t('scroll_to_see_more') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuPreOrderDish from './Dish'

export default {
  name: 'MenuPreOrderCategory',
  components: { MenuPreOrderDish },
  props: {
    categoryAmount: {
      type: Number,
      default: 0
    },
    hasChildMenu: {
      type: Boolean,
      default: false
    },
    preOrderIsRequired: {
      type: Boolean,
      default: false
    },
    category: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      renderIndex: 0,
      showScrollInfo: false,
      show: false
    }
  },
  computed: {
    visible() {
      if (this.filteredDishes.length === 0) {
        return false
      }

      if (this.category.children) {
        if (this.model.reservation.children > 0) {
          return true
        }

        return false
      }

      return true
    },
    filteredDishes() {
      return this.category.dishes.filter((dish) => dish.minimum <= this.model.reservation.menu_persons)
    },
    selectDishes() {
      return this.model.reservation.preOrders.filter((preOrder) => preOrder.category_id == this.category.id)
    }
  },
  methods: {
    toggle() {
      if (this.preOrderIsRequired && this.categoryAmount == 1 && !this.category.multiple) {
        return false
      }

      this.show = !this.show

      const myDiv = document.getElementById('menu-scrolling')
      myDiv.scrollTop = 0

      if (this.show) {
        setTimeout(() => {
          const name = 'category-' + this.category.id
          const element = document.getElementById(name)

          if (element && element.scrollHeight > element.clientHeight) {
            this.showScrollInfo = true
          }
        }, 200)
      }
    },
    selectDish(dish) {
      this.renderIndex++
      if (this.preOrderIsRequired && this.categoryAmount == 1 && !this.category.multiple) {
        if (this.model.reservation.preOrders.length == 0) {
          this.$root.$emit('wizard-next-tab')
        }
      } else if (!this.category.multiple || this.categoryAmount > 1) {
        this.show = false
      }

      this.$emit('select-dish', {
        id: dish.id,
        title: dish.title,
        price: dish.price,
        amount: dish.amount,
        category_id: dish.category_id,
        category: {
          multiple: this.category.multiple,
          children: this.category.children
        }
      })
    }
  }
}
</script>
