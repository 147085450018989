<template>
  <div class="date-step">
    <widget-header :title="$t('step.date.title')" :sub-title="$t('step.date.desc')" />
    <alert-warning :text="data.widgetMessage.date"></alert-warning>
    <flat-pickr v-if="!showNote" @on-change="setDate" :config="config" v-model="date"></flat-pickr>
    <div
      v-if="notes.length > 0 && showNote"
      class="flex flex-col items-center justify-center p-3 text-center"
      style="height: 350px"
    >
      <div>
        <div class="mx-auto">
          <i class="fal fa-info-circle text-4xl text-primary opacity-75"></i>
        </div>
        <div class="mt-5 w-56 text-center text-center">
          <p class="text-md mb-2 font-bold text-gray-800">
            {{ $t('step.date.information') }}
          </p>
          <p class="mb-2 text-sm text-gray-500" v-for="note in notes" :key="'note-' + note.hash">
            {{ note.text }}
          </p>
        </div>
      </div>
      <div class="mt-12">
        <btn-second @click="resetDate" :title="$t('btn.previous')" />
        <btn-primary @click="setDateNote()" :title="$t('btn.next')" />
      </div>
    </div>
    <div class="absolute right-0 top-0 mr-3 mt-7">
      <select
        @change="changeLocale"
        v-model="locale"
        class="form-select border-0 pr-7 text-primary"
        style="font-size: 13px !important"
      >
        <option value="de">Deutsch</option>
        <option value="en">English</option>
      </select>
    </div>
    <a
      v-if="data.restaurant.showTablyBrand"
      href="https://www.usetably.com?ref=widget"
      target="_blank"
      class="absolute bottom-0 left-0 right-0 p-2 text-center"
    >
      <img class="mx-auto w-8" src="https://cdn.usetably.com/assets/generic/logo-primary.svg" />
    </a>
  </div>
</template>

<script>
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import WidgetHeader from '../components/Header/Index'
import { German } from 'flatpickr/dist/l10n/de.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import AlertWarning from '../components/Alert/Warning'
import { language } from '../utils/browserLanguage'
import BtnSecond from '../components/Button/Second'
import BtnPrimary from '../components/Button/Primary'

let dateLanguage = english
dateLanguage.firstDayOfWeek = 1

if (language === 'de') {
  dateLanguage = German
}

export default {
  name: 'DateStep',
  data() {
    return {
      showNote: false,
      notes: [],
      date: null,
      locale: language,
      config: {
        inline: true,
        altFormat: 'M j, Y',
        altInput: true,
        minDate: 'today',
        yearSelectorType: 'input | static',
        maxDate: new Date().fp_incr(this.data.settingReservation.max_days_to_book),
        dateFormat: 'Y-m-d',
        locale: dateLanguage,
        disable: [
          (date) => {
            return this.checkWeekDay(date)
          }
        ]
      }
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    AlertWarning,
    BtnSecond,
    BtnPrimary,
    WidgetHeader,
    flatPickr
  },
  watch: {
    '$parent.active'() {
      if (this.$parent.active) {
        this.$emit('set-visibility-btn', false)
      }
    }
  },
  mounted() {
    this.$emit('set-locale', this.locale)
  },
  methods: {
    getNotes() {
      this.notes = this.data.notes.filter((note) => {
        return moment(this.date) >= moment(note.from) && moment(this.date) <= moment(note.to)
      })
    },
    resetDate() {
      this.date = null
      this.showNote = false
    },
    checkWeekDay(date) {
      let weekIndex = date.getDay()

      // get days that are closed based on config
      const closedDay = this.data.closedDates.find((payload) => {
        return (
          moment(payload.from).format('YYYYMMDD') <= moment(date).format('YYYYMMDD') &&
          moment(payload.to).format('YYYYMMDD') >= moment(date).format('YYYYMMDD')
        )
      })

      if (closedDay) {
        return true
      }

      // get days that are open based on time config
      const openDay = this.data.openDates.find((payload) => {
        return (
          moment(payload.from).format('YYYYMMDD') <= moment(date).format('YYYYMMDD') &&
          moment(payload.to).format('YYYYMMDD') >= moment(date).format('YYYYMMDD')
        )
      })

      if (openDay) {
        return false
      }

      // fix index with sundays index 0
      if (weekIndex == 0) {
        weekIndex = 7
      }

      return !Object.values(this.data.closedWeekDays)[weekIndex - 1]
    },
    setDateNote() {
      this.showNote = false
      this.$emit('set-date', moment(this.date).format('YYYY-MM-DD'))
    },
    changeLocale() {
      window.location = '?locale=' + this.locale
      this.$emit('set-locale', this.locale)
    },
    setDate(date) {
      this.getNotes()

      if (this.notes.length > 0) {
        this.showNote = true
        return
      }

      this.showNote = false
      this.$emit('set-date', moment(date[0]).format('YYYY-MM-DD'))
    }
  }
}
</script>
