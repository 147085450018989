import Vue from "vue";
import Router from "vue-router";
import App from "./App.vue";
import Widget from "./page/Widget.vue";
import VueI18n from 'vue-i18n'
import VueFormGenerator from 'vue-form-generator'
import TailPhoneInput from './components/Input/Phone.vue'
import TailInput from './components/Input/Input.vue'
import TailEmailInput from './components/Input/Email.vue'
import TailCheckbox from './components/Input/Checkbox.vue'
import TailTextArea from './components/Input/TextArea.vue'
import TailSelect from './components/Input/Select.vue'
import { language } from './utils/browserLanguage'
import en from './translations/en.json'
import de from './translations/de.json'

Vue.config.productionTip = false;
Vue.use(Router)
Vue.use(VueI18n)
Vue.use(VueFormGenerator)
Vue.component('fieldTailTextArea', TailTextArea)
Vue.component('fieldTailInput', TailInput)
Vue.component('fieldTailEmailInput', TailEmailInput)
Vue.component('fieldTailPhoneInput', TailPhoneInput)
Vue.component('fieldTailCheckbox', TailCheckbox)
Vue.component('fieldTailSelect', TailSelect)

Vue.filter('formatMaxLength', function (value, maxLength = 15) {
  if (value && value.length > maxLength) {
    return value.substring(0, maxLength) + '...'
  }

  return value
})

const router = new Router({
  mode: "hash",
  routes: [
    { path: "/widget/:apikey", component: Widget },
    { path: "/event-widget", component: Widget },
    { path: "/selfcheckin", component: Widget },
  ],
});

const messages = {
  en: en,
  de: de
}

const i18n = new VueI18n({
  fallbackLocale: 'en',
  locale: language,
  messages: messages
})


new Vue({
  render: (h) => h(App),
  i18n,
  router,
}).$mount("#tably");
