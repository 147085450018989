<template>
  <div class="mb-4 w-full">
    <div class="relative flex items-start">
      <div class="absolute flex h-5 items-center">
        <input
          :id="schema.model"
          v-model="value"
          :disabled="disabled"
          type="checkbox"
          class="transition form-checkbox h-4 w-4 text-primary duration-150 ease-in-out"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label :for="schema.model" class="font-medium text-gray-700" v-html="schema.title" />
        <p v-if="schema.desc" class="text-gray-500" v-text="schema.desc" />
        <p v-if="schema.help" class="text-gray-500" v-text="schema.help" />
        <p v-if="fieldErrors" class="mt-2 whitespace-normal text-sm text-red-600" v-text="fieldErrors" />
      </div>
    </div>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  name: 'CheckboxInput',
  mixins: [abstractField],
  computed: {
    fieldErrors() {
      try {
        // eslint-disable-next-line no-prototype-builtins
        if (this.$page && this.$page.errors && this.$page.errors.hasOwnProperty(this.schema.model)) {
          return this.$page.errors[this.schema.model][0]
        }

        // eslint-disable-next-line no-prototype-builtins
        if (this.$page && this.$page.errors && this.$page.errors.hasOwnProperty(this.schema.errorKey)) {
          return this.$page.errors[this.schema.errorKey][0]
        }

        return false
      } catch (e) {
        console.log(e)
      }

      return false
    }
  }
}
</script>
