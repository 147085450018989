<template>
  <div class="animated fadeIn mx-auto mb-4 flex h-full flex-col justify-center pb-8">
    <animation-confetti />
    <div class="mb-4 text-center text-3xl text-green-500">
      <i class="fad fa-check-circle" dusk="reservation-success" />
    </div>
    <widget-header :title="$t('step.done.title')" :sub-title="$t('step.done.desc')" />
    <div data-cypress="success" class="text-center text-sm text-gray-800">
      <div v-if="showInquiryNote" class="border-l-4 border-yellow-400 bg-yellow-50 p-4">
        <div class="flex leading-5">
          <i class="fad fa-exclamation-triangle pr-3 text-yellow-500"></i>
          <p class="text-left text-sm leading-normal text-yellow-700" v-text="inquiryNote" />
        </div>
      </div>

      <div
        v-if="url && data.settingReservation && data.settingReservation.customerEdit"
        class="mt-6 flex w-full justify-center"
      >
        <a
          :href="url"
          target="_blank"
          class="
            border
            focus:outline-none focus:shadow-outline-primary
            transition
            relative
            z-10
            flex
            h-10
            flex-col
            items-center
            justify-center
            rounded-md
            border-transparent
            bg-primary
            px-4
            py-2
            text-sm
            font-medium
            text-white
            duration-150
            ease-in-out
            focus:border-primary
            active:bg-purple-700
          "
        >
          {{ $t('step.done.btn_show_details') }}
        </a>
      </div>
      <span
        v-if="url && data.settingWidget && data.settingWidget.has_external_link"
        class="relative z-10 -mb-4 mt-3 inline-flex rounded-md shadow-sm"
      >
        <a
          :href="link"
          target="_blank"
          type="button"
          class="
            border
            focus:outline-none focus:shadow-outline-primary
            transition
            relative
            z-10
            flex
            h-10
            flex-col
            items-center
            justify-center
            rounded-md
            border-gray-200
            px-4
            py-2
            text-sm
            font-medium
            text-gray-800
            duration-150
            ease-in-out
            hover:bg-gray-100
          "
        >
          {{ data.settingWidget.external_link_title }}
        </a>
      </span>
      <div v-if="shareLinks" class="mt-12 text-xs font-bold uppercase tracking-wider text-gray-800">
        {{ $t('step.done.share') }}
      </div>
      <div v-if="shareLinks" class="relative z-20 mt-2 text-xs uppercase text-gray-500">
        <a :href="shareLinks.whatsapp" target="_blank" class="mx-2">Whatsapp</a>
        <a :href="shareLinks.email" target="_blank" class="mx-2">E-Mail</a>
        <a :href="shareLinks.calendar" target="_blank" class="mx-2">Kalender</a>
      </div>
    </div>

    <div class="absolute bottom-0 left-0 right-0 p-4 text-center">
      <img class="mx-auto h-6" :src="data.brand" />
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler'
import WidgetHeader from '../components/Header/Index'
import AnimationConfetti from '../components/Animation/Confetti'

export default {
  name: 'ReservationDone',
  components: {
    WidgetHeader,
    AnimationConfetti
  },
  props: {
    showInquiryNote: {
      type: Boolean,
      default: true
    },
    shareLinks: {
      type: Object,
      default: () => {}
    },
    url: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      localStorageSaved: false
    }
  },
  computed: {
    inquiryNote() {
      return this.data.widgetMessage.inquiry_reconfirmation.length > 1
        ? this.data.widgetMessage.inquiry_reconfirmation
        : this.$t('step.done.wait_on_submit')
    },
    link() {
      return (
        this.data.settingWidget.external_link +
        `?firstname=${this.model.customer.first_name}&lastname=${this.model.customer.last_name}&email=${this.model.customer.email}&phone=${this.model.customer.phone}&date=${this.model.reservation.date}&time=${this.model.reservation.time}`
      )
    }
  },
  mounted() {
    var sound = new Howl({
      src: ['/assets/sounds/notification.mp3'],
      autoplay: true,
      volume: 0.3
    })

    sound.play()
  }
}
</script>
