<template>
  <div
    @click="$emit('select')"
    class="
      rounded
      border
      mb-3
      flex
      cursor-pointer
      justify-between
      border-gray-200
      bg-white
      p-3
      hover:bg-gray-100
    "
  >
    <div>
      <div class="pb-1 text-sm font-bold" v-text="title"></div>
      <div class="text-xs leading-tight text-gray-600" v-text="text"></div>
    </div>
    <div
      style="min-width: 1.5rem; max-width: 1.5rem"
      class="ml-3 flex h-6 w-6 flex-auto items-center justify-center opacity-50"
    >
      <svg
        class="w-6"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M9 5l7 7-7 7"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuCategory',
  props: {
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    }
  }
}
</script>
