export const duration = () => []

/*
export const duration = () => [
  { id: '30', name: '30 ' + i18n.tc('site.minutes') },
  { id: '45', name: '45 ' + i18n.tc('site.minutes') },
  { id: '50', name: '50 ' + i18n.tc('site.minutes') },
  { id: '60', name: '1 ' + i18n.tc('site.hour') },
  {
    id: '75',
    name: '1 ' + i18n.tc('site.hour') + ' 15 ' + i18n.tc('site.minutes')
  },
  {
    id: '90',
    name: '1 ' + i18n.tc('site.hour') + ' 30 ' + i18n.tc('site.minutes')
  },
  {
    id: '105',
    name: '1 ' + i18n.tc('site.hour') + ' 45 ' + i18n.tc('site.minutes')
  },
  { id: '120', name: '2 ' + i18n.tc('site.hours') + '' },
  {
    id: '135',
    name: '2 ' + i18n.tc('site.hours') + ' 15 ' + i18n.tc('site.minutes')
  },
  {
    id: '150',
    name: '2 ' + i18n.tc('site.hours') + ' 30 ' + i18n.tc('site.minutes')
  },
  {
    id: '165',
    name: '2 ' + i18n.tc('site.hours') + ' 45 ' + i18n.tc('site.minutes')
  },
  { id: '180', name: '3 ' + i18n.tc('site.hours') + '' },
  {
    id: '195',
    name: '3 ' + i18n.tc('site.hours') + ' 15 ' + i18n.tc('site.minutes')
  },
  {
    id: '210',
    name: '3 ' + i18n.tc('site.hours') + ' 30 ' + i18n.tc('site.minutes')
  },
  {
    id: '225',
    name: '3 ' + i18n.tc('site.hours') + ' 45 ' + i18n.tc('site.minutes')
  },
  { id: '240', name: '4 ' + i18n.tc('site.hours') },
  {
    id: '255',
    name: '4 ' + i18n.tc('site.hours') + ' 15 ' + i18n.tc('site.minutes')
  },
  {
    id: '270',
    name: '4 ' + i18n.tc('site.hours') + ' 30 ' + i18n.tc('site.minutes')
  },
  {
    id: '285',
    name: '4 ' + i18n.tc('site.hours') + ' 45 ' + i18n.tc('site.minutes')
  },
  { id: '300', name: '5 ' + i18n.tc('site.hours') },
  {
    id: '315',
    name: '5 ' + i18n.tc('site.hours') + ' 15 ' + i18n.tc('site.minutes')
  },
  {
    id: '330',
    name: '5 ' + i18n.tc('site.hours') + ' 30 ' + i18n.tc('site.minutes')
  },
  {
    id: '345',
    name: '5 ' + i18n.tc('site.hours') + ' 45 ' + i18n.tc('site.minutes')
  },
  { id: '360', name: '6 ' + i18n.tc('site.hours') }
]
*/