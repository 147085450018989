<template>
  <div class="mx-auto mb-2 flex flex-row" style="width: 220px">
    <div class="w-10 rounded-md text-2xl shadow-lg">
      <button
        @click="remove"
        data-cypress="remove-person"
        type="button"
        class="
          border
          focus:outline-none focus:shadow-outline-primary
          transition
          flex
          h-10
          w-full
          flex-col
          items-center
          justify-center
          rounded-md
          border-transparent
          bg-primary
          px-4
          py-2
          text-sm
          font-medium
          text-white
          duration-150
          ease-in-out
          focus:border-primary
          active:bg-purple-700
        "
      >
        <i class="fal fa-minus"></i>
      </button>
    </div>
    <div class="whitespace-no-wrap flex h-10 w-24 flex-1 items-center justify-center text-sm text-gray-700">
      {{ value }} {{ $tc(title, value) }}
    </div>
    <div class="w-10 rounded-md text-2xl shadow-lg">
      <button
        @click="add"
        type="button"
        data-cypress="add-person"
        class="
          border
          focus:outline-none focus:shadow-outline-primary
          transition
          flex
          h-10
          w-full
          flex-col
          items-center
          justify-center
          rounded-md
          border-transparent
          bg-primary
          px-4
          py-2
          text-sm
          font-medium
          text-white
          duration-150
          ease-in-out
          focus:border-primary
          active:bg-purple-700
        "
      >
        <i class="fal fa-plus"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonSelect',
  props: {
    limitReached: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    default: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      value: this.default
    }
  },
  methods: {
    add() {
      if (!this.limitReached) {
        this.value++
        this.$emit('add')
      }
    },
    remove() {
      if (this.value > this.min) {
        this.value--
        this.$emit('remove')
      }
    },
    setTime(time) {
      this.$emit('set-time', time)
    }
  }
}
</script>
