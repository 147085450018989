<template>
  <div class="mb-5 text-center">
    <h2 class="text-xl font-medium leading-6 text-gray-900">{{ title }}</h2>
    <p class="text-sm leading-5 text-gray-500">{{ subTitle }}</p>
  </div>
</template>

<script>
export default {
  name: 'WidgetHeader',
  props: {
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    }
  }
}
</script>
