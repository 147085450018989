const userLang = window.customLanguage || window.navigator.language || navigator.language || navigator.userLanguage
let shortCode = 'en'

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const locale = urlParams.get('locale')

if (userLang.substring(0, 2) == 'de') {
  shortCode = 'de'
}

if(['en', 'de']. includes(locale)) {
  shortCode = locale;
}

export const language = shortCode
 