<template>
  <div id="tably">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "./sass/widget.scss";
</style>
