<template>
  <span :class="fluid ? 'btn-fluid flex' : 'inline-flex'" class="rounded-md shadow-sm">
    <button
      data-cypress="btn-primary"
      type="button"
      :disabled="disabled || loading"
      :class="{ 'cursor-not-allowed opacity-50': disabled }"
      class="
        whitespace-no-wrap
        border
        focus:outline-none focus:shadow-outline-primary
        transition
        relative
        inline-flex
        items-center
        rounded-md
        border-transparent
        bg-primary
        px-3
        py-1.5
        text-sm
        font-medium
        leading-5
        text-white
        duration-150
        ease-in-out
        hover:bg-primary-dark
        focus:border-primary
        active:bg-primary-dark
        lg:px-4 lg:py-2
      "
      @click="$emit('click')"
    >
      <div class="absolute inset-0 flex items-center justify-center bg-primary" v-show="loading">
        <i class="fad fa-circle-notch fa-spin text-lg"></i>
      </div>
      <i v-if="icon" class="mr-3" :class="icon + ' ' + iconType" />
      {{ title }}
    </button>
  </span>
</template>

<script>
export default {
  name: 'BtnPrimary',
  props: {
    iconType: {
      type: String,
      default: 'fad'
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fluid: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>
