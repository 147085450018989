<template>
  <div
    data-cypress="dish-entry"
    class="animated fadeIn px-2 py-3 text-sm"
    :class="{ 'border-b border-dashed border-gray-200': last }"
  >
    <div class="flex items-center justify-between">
      <div class="w-full flex-1">
        <div class="text-xs font-bold">{{ dish.title }}</div>
        <div class="text-xs text-gray-600">{{ dish.desc }}</div>
      </div>
      <div
        v-if="isSelected < 0"
        :class="{ 'opacity-0': showAmountSelect }"
        class="rounded shadow ml-3 w-20 bg-primary px-2 py-1 text-center text-xs font-bold text-white"
        @click="selectDish"
      >
        + {{ dish.price | formatPrice }}
      </div>
      <div
        v-if="isSelected >= 0"
        class="rounded shadow ml-3 w-20 bg-primary px-2 py-1 text-center text-xs font-bold text-white"
        @click="selectDish"
      >
        {{ $t('btn.remove') }}
      </div>
    </div>
    <div v-if="showAmountSelect" class="flex items-center justify-between">
      <div class="relative mt-1 w-32">
        <span class="inline-flex rounded-md shadow-sm" @click="decreaseDish">
          <button
            type="button"
            class="
              border
              rounded
              focus:outline-none focus:shadow-outline-primary
              transition
              inline-flex
              items-center
              border-transparent
              bg-primary
              px-2
              py-1
              text-lg
              font-medium
              leading-4
              text-white
              duration-150
              ease-in-out
              hover:bg-primary
              focus:border-primary
              active:bg-purple-700
            "
          >
            -
          </button>
        </span>
        <span class="px-3 text-lg">{{ amount }}</span>
        <span class="inline-flex rounded-md shadow-sm" @click="increaseDish">
          <button
            type="button"
            class="
              border
              rounded
              focus:outline-none focus:shadow-outline-primary
              transition
              inline-flex
              items-center
              border-transparent
              bg-primary
              px-2
              py-1
              text-lg
              font-medium
              leading-4
              text-white
              duration-150
              ease-in-out
              hover:bg-primary
              focus:border-primary
              active:bg-purple-700
            "
          >
            +
          </button>
        </span>
      </div>
      <button
        class="
          rounded
          shadow
          ml-3
          w-20
          cursor-pointer
          bg-primary
          px-2
          py-1
          text-center text-xs
          font-bold
          uppercase
          text-white
        "
        @click="handleMultiple"
      >
        {{ $t('btn.choose') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuPreOrderDish',
  props: {
    last: {
      type: Boolean,
      default: false
    },
    hasChildMenu: {
      type: Boolean,
      default: false
    },
    dish: {
      type: Object,
      default: () => {}
    },
    category: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: false,
      showAmountSelect: false,
      amount: 2
    }
  },
  computed: {
    isSelected() {
      return this.model.reservation.preOrders.findIndex((o) => o.id == this.dish.id)
    }
  },
  watch: {
    model: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.hasChildMenu == false) {
          this.amount = this.model.reservation.menu_persons
          return
        }

        if (this.category.children) {
          this.amount = this.model.reservation.children
          return
        }

        this.amount = this.model.reservation.menu_persons
      }
    }
  },
  methods: {
    increaseDish() {
      this.amount++
    },
    decreaseDish() {
      if (this.amount > 1) {
        this.amount--
      }
    },
    selectDish() {
      if (this.category.multiple && this.isSelected < 0) {
        this.showAmountSelect = true
        return
      }

      // eslint-disable-next-line
      this.dish.amount = this.amount
      this.$emit('select-dish', this.dish)
    },
    handleMultiple() {
      // eslint-disable-next-line
      this.dish.amount = this.amount
      if (this.showAmountSelect) {
        this.$emit('select-dish', this.dish)
        this.showAmountSelect = false
      }
    }
  }
}
</script>
