<template>
  <span
    :class="
      (disabled ? 'opacity-50' : 'opacity-100', fluid ? 'btn-fluid flex' : 'inline-flex') +
      '  rounded-md shadow-sm'
    "
  >
    <button
      data-cypress="btn-secondary"
      type="button"
      :disabled="disabled"
      class="
        border
        focus:outline-none
        transition
        inline-flex
        items-center
        rounded-md
        border-gray-300
        bg-white
        px-3
        py-1.5
        text-sm
        font-medium
        leading-5
        text-gray-700
        duration-150
        ease-in-out
        hover:text-gray-500
        active:bg-gray-50 active:text-gray-800
        lg:px-4 lg:py-2
      "
      @click="$emit('click')"
    >
      <i v-if="loading" class="fad fa-circle-notch fa-spin mr-3"></i>
      <i v-if="icon && !title" class="fad" :class="icon" />
      <i v-if="icon && title" class="fad mr-3" :class="icon" />
      {{ title }}
    </button>
  </span>
</template>

<script>
export default {
  name: 'BtnSecond',
  props: {
    icon: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    fluid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>
