<template>
  <div class="mb-4">
    <widget-header :title="$t('step.lounge.title')" :sub-title="$t('step.lounge.desc')" />
    <alert-warning :text="data.widgetMessage.lounge" />
    <div v-if="loading" class="absolute inset-0 z-30 flex items-center justify-center bg-white">
      <i class="fad fa-spin fa-spinner-third text-3xl"></i>
    </div>
    <div class="relative">
      <div
        class="border rounded scrolling-touch overflow-auto"
        style="max-height: 23rem; min-height: 23rem"
        @scroll="showScrollInfo = false"
        id="lounge-scrolling"
      >
        <lounge :key="room.id" v-for="room in roomsPayload.rooms" @select="selectLounge" :lounge="room" />
      </div>
      <div
        v-if="showScrollInfo"
        class="
          animated
          fadeIn
          gradient-white
          absolute
          bottom-0
          left-0
          right-0
          flex
          items-center
          justify-center
          pb-2
          pt-4
          text-xs
          font-bold
          text-gray-700
        "
      >
        <svg
          class="mr-2 h-4"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M19 9l-7 7-7-7"></path>
        </svg>
        {{ $t('scroll_to_see_more') }}
      </div>
    </div>
  </div>
</template>

<script>
import WidgetHeader from '../components/Header/Index'
import Lounge from '../components/Lounge/Index'
import AlertWarning from '../components/Alert/Warning'
import axios from 'axios'

export default {
  name: 'LoungeStep',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    goesForward: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      date: null,
      roomsPayload: [],
      loading: false,
      showScrollInfo: false
    }
  },
  components: {
    AlertWarning,
    WidgetHeader,
    Lounge
  },
  watch: {
    '$parent.active'() {
      if (this.$parent.active) {
        this.$emit('set-visibility-btn', false)
        this.getRooms()
      }
    }
  },
  mounted() {
    this.$emit('toggle-next-btn', true)
  },
  computed: {
    dateFormatted() {
      return this.model.reservation.date
    },
    personsAmount() {
      return this.model.reservation.adults + this.model.reservation.children
    }
  },
  methods: {
    getRooms() {
      this.loading = true
      axios
        .get(
          process.env.VUE_APP_ENDPOINT + `/api-widget/reservation/${this.data.user.apikey}/available-rooms?date=${this.dateFormatted}&persons=${this.personsAmount}&time=${this.model.reservation.time}`
        )
        .then((response) => {
          this.loading = false
          this.roomsPayload = response.data
          this.checkScrollHover()

          if (this.roomsPayload.rooms.length === 0) {
            if (this.goesForward) {
              this.$emit('select-room', {
                id: null,
                type: 'roomGroup'
              })
            }
            if (!this.goesForward) {
              this.$root.$emit('wizard-prev-tab')
            }
          }
        })
        .catch(() => {
          this.loading = false
          this.rooms = []
        })
    },
    checkScrollHover() {
      setTimeout(() => {
        const element = document.getElementById('lounge-scrolling')

        if (element) {
          element.scrollTop = 0

          if (element.scrollHeight > element.clientHeight) {
            this.showScrollInfo = true
          }
        }
      }, 200)
    },
    selectLounge(id) {
      this.$emit('select-room', {
        id: id,
        type: this.roomsPayload.type
      })
    },
    removePersons() {
      this.$emit('remove-persons')
    },
    addPersons() {
      this.$emit('add-persons')
    },
    removeChildren() {
      this.$emit('remove-children')
    },
    addChildren() {
      this.$emit('add-children')
    }
  }
}
</script>
