<template>
  <div>
    <widget-header
      :title="$t('step.time.title')"
      :sub-title="$t('step.time.desc', { date: dateReadFormat })"
    />
    <alert-warning :text="data.widgetMessage.time" />
    <div v-if="loading" class="h-24" />
    <div v-if="loading" class="absolute left-0 right-0 top-0 mt-24 pt-6 text-center">
      <i class="fad fa-spin fa-spinner-third text-3xl"></i>
    </div>
    <time-wrapper
      v-if="dateFormatted && !loading"
      ref="timeWrapper"
      @set-time="setTime"
      @set-duration="setDuration"
      :init-duration="duration"
      :key="dateFormatted + personsAmount"
      :date="model.reservation.date"
      :model="model"
      :data="data"
      :hour-before="data.settingReservation.hour_before"
      :items="times"
    />
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import WidgetHeader from '../components/Header/Index'
import TimeWrapper from '../components/Time/Index'
import AlertWarning from '../components/Alert/Warning'
import { language } from '../utils/browserLanguage'

export default {
  name: 'TimeStep',
  data() {
    return {
      loading: true,
      duration: null,
      date: null,
      times: {
        breakfast: [],
        lunch: [],
        dinner: []
      }
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    AlertWarning,
    TimeWrapper,
    WidgetHeader
  },
  watch: {
    'model.reservation.date'() {
      this.fetchTimes()
    },
    '$parent.active'() {
      if (this.$parent.active) {
        this.fetchTimes()
        this.$emit('set-visibility-btn', false)
      }
    }
  },
  computed: {
    dateFormatted() {
      return this.model.reservation.date
    },
    personsAmount() {
      return this.model.reservation.adults + this.model.reservation.children
    },
    dateReadFormat() {
      return moment(this.model.reservation.date).locale(language).format('dddd DD.MM.YYYY')
    }
  },
  methods: {
    setTime(time) {
      this.$emit('set-time', time)
    },
    setDuration(duration) {
      this.$emit('set-duration', duration)
      this.duration = duration
      this.fetchTimes(false)
    },
    fetchTimes(reset = true) {
      if (reset) {
        this.loading = true
        this.times = {
          breakfast: [],
          lunch: [],
          dinner: []
        }
      }

      axios
        .get(
         process.env.VUE_APP_ENDPOINT +  `/api-widget/reservation/${this.data.user.apikey}/times?date=${this.dateFormatted}&persons=${this.personsAmount}&duration=${this.duration}`
        )
        .then((response) => {
          this.loading = false
          this.times = response.data
        })
        .catch(() => {
          this.loading = false
          this.times = []
        })
    }
  }
}
</script>
