<template>
  <div class="mb-4 w-full">
    <div class="relative mt-1 rounded-md shadow-sm">
      <input
        v-model="value"
        autocomplete="no"
        :disabled="disabled"
        :maxlength="schema.max"
        @blur="blur"
        :name="schema.model"
        :placeholder="schema.placeholder"
        class="form-input block w-full text-sm sm:leading-5"
        :type="schema.inputType"
      />
    </div>
    <p v-if="!emailCorrect" class="mt-1 text-xs text-red-500" v-text="$t('email_spelling_errors')" />
    <p v-if="schema.help" class="mt-1 text-sm text-gray-500" v-text="schema.help" />
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import axios from 'axios'

export default {
  name: 'EmailInput',
  mixins: [abstractField],
  data() {
    return {
      emailCorrect: true
    }
  },
  methods: {
    blur() {
      this.validateEmail()
    },
    validateEmail() {
      axios.get(process.env.VUE_APP_ENDPOINT + `/api-widget/email-validation?q=${this.value}`).then((response) => {
        this.emailCorrect = response.data
      })
    }
  }
}
</script>
