<template>
  <div
    @click="select"
    class="border-b last-no-border flex cursor-pointer select-none items-center bg-white hover:bg-gray-100"
    style="min-height: 60px"
    :class="{ 'opacity-50': !lounge.available }"
  >
    <div class="flex flex-1 items-center">
      <div
        v-if="lounge.image"
        class="rounded shadow ml-1 h-14 w-14 overflow-hidden bg-gray-300 bg-cover"
        :style="{ backgroundImage: 'url(' + lounge.image + ')' }"
      ></div>
      <div v-else class="ml-1 flex h-14 w-14 items-center justify-center">
        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          focusable="false"
          data-auto="tableCategoriesIcon"
          class="w-6 opacity-75"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              d="M17,9 L7,9 L7,11 L17,11 L17,9 Z M19,9 L19,18 C19,18.5522847 18.5522847,19 18,19 C17.4477153,19 17,18.5522847 17,18 L17,13 L7,13 L7,18 C7,18.5522847 6.55228475,19 6,19 C5.44771525,19 5,18.5522847 5,18 L5,9 L3,9 C2.44771525,9 2,8.55228475 2,8 L2,6 C2,5.44771525 2.44771525,5 3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,8 C22,8.55228475 21.5522847,9 21,9 L19,9 Z"
              fill="currentColor"
            ></path>
          </g>
        </svg>
      </div>
      <div class="w-full flex-1 p-2">
        <span class="text-sm font-semibold" v-text="lounge.name" />
        <div
          v-if="lounge.description"
          class="text-xs leading-tight text-gray-500"
          v-text="lounge.description"
        />
      </div>
    </div>
    <div class="mr-2 pl-2 text-right">
      <span class="inline-flex rounded-md shadow-sm">
        <button
          type="button"
          class="
            border
            rounded
            focus:outline-none
            transition
            inline-flex
            items-center
            border-transparent
            bg-primary
            px-2.5
            py-1.5
            text-xs
            font-medium
            leading-4
            text-white
            duration-150
            ease-in-out
            focus:border-purple-700 focus:shadow-outline-indigo
            active:bg-purple-700
          "
        >
          <i class="fal fa-plus"></i>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoungeSelection',
  props: {
    lounge: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    select() {
      if (this.lounge.available) {
        this.$emit('select', this.lounge.id)
      }
    }
  }
}
</script>
