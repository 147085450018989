<template>
  <div class="animated fadeIn mb-4 flex h-full flex-col justify-center pb-24">
    <div class="mb-4 text-center text-3xl text-red-500">
      <i class="fad fa-exclamation-triangle" />
    </div>
    <widget-header :title="$t('step.error.title')" :sub-title="$t('step.error.desc')" />
    <div class="px-4 text-center text-sm text-gray-800">
      <div class="mt-6 flex w-full justify-center">
        <a
          :href="currentLink"
          target="_parent"
          class="
            border
            focus:outline-none focus:shadow-outline-primary
            transition
            relative
            z-10
            flex
            h-10
            flex-col
            items-center
            justify-center
            rounded-md
            border-transparent
            bg-primary
            px-4
            py-2
            text-sm
            font-medium
            text-white
            duration-150
            ease-in-out
            focus:border-primary
            active:bg-purple-700
          "
        >
          {{ $t('step.error.retry') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetHeader from '../components/Header/Index'

export default {
  name: 'ReservationError',
  components: {
    WidgetHeader
  },
  computed: {
    currentLink() {
      return window.location
    }
  }
}
</script>
