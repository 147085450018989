<template>
  <div :class="{ 'has-occcasion': scopeModel.occasion }">
    <widget-header
      :title="showCompanyInput ? $t('step.customer.company_data') : $t('step.customer.title')"
      :sub-title="
        $t('step.customer.desc', {
          date: dateReadFormat,
          time: timeReadFormat,
          persons: persons
        })
      "
    />
    <div v-show="!showCompanyInput" class="relative mx-auto w-full max-w-xs">
      <div
        v-if="data.settingWidget.company"
        @click="toggleCompanyData"
        class="animated fadeIn absolute right-0 top-0 z-10 cursor-pointer pb-3 pr-2 pt-3 text-sm"
      >
        +
        {{
          (scopeModel.company ? scopeModel.company : $t('step.customer.add_company_data'))
            | formatMaxLength(15)
        }}
      </div>
      <vue-form-generator
        ref="form"
        tag="div"
        class="-mt-3"
        :schema="schema"
        @model-updated="setCustomer"
        :model="scopeModel"
        :options="formOptions"
      />
    </div>
    <div v-show="showCompanyInput" class="relative mx-auto w-full max-w-xs">
      <vue-form-generator
        ref="companyForm"
        tag="div"
        class="-mt-3"
        :schema="schemaCompany"
        @model-updated="setCustomer"
        :model="scopeModel"
        :options="formOptions"
      />

      <div class="mt-3 text-center">
        <btn-primary :title="$t('btn.save')" @click="toggleCompanyData" />
      </div>
    </div>
  </div>
</template>

<script>
import WidgetHeader from '../components/Header/Index'
import VueFormGenerator from 'vue-form-generator'
import moment from 'moment'
import axios from 'axios'
import { language } from '../utils/browserLanguage'
import BtnPrimary from '../components/Button/Primary'

export default {
  name: 'CustomerStep',
  props: {
    errors: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      date: null,
      showCompanyInput: false,
      results: [],
      scopeModel: {
        first_name: null,
        last_name: null,
        email: null,
        phone: '',
        notice: null,
        occasion: null,
        customField: null,
        newsletter_optin: false,
        gdpr: false,
        company: null,
        street: null,
        place: null,
        country: null
      },
      schemaCompany: {
        fields: [
          {
            type: 'TailInput',
            placeholder: this.$t('form.company'),
            model: 'company',
            styleClasses: 'w-full px-3 -mb-3',
            required: true,
            validator: VueFormGenerator.validators.required.locale({
              fieldIsRequired: this.$t('form.required')
            })
          },
          {
            type: 'TailInput',
            placeholder: this.$t('form.street'),
            model: 'street',
            styleClasses: 'w-full px-3 -mb-3',
            required: true,
            validator: VueFormGenerator.validators.required.locale({
              fieldIsRequired: this.$t('form.required')
            })
          },
          {
            type: 'TailInput',
            placeholder: this.$t('form.place'),
            model: 'place',
            styleClasses: 'w-full px-3 -mb-3',
            required: true,
            validator: VueFormGenerator.validators.required.locale({
              fieldIsRequired: this.$t('form.required')
            })
          },
          {
            type: 'TailSelect',
            placeholder: this.$t('form.country'),
            model: 'country',
            styleClasses: 'w-full px-3 -mb-3',
            values: this.data.countries
              .map((country) => {
                return {
                  id: country.id,
                  name: this.$i18n.locale === 'de' ? country.name_de : country.name_en
                }
              })
              .sort((a, b) => a.name.localeCompare(b.name))
          }
          // {
          //   type: 'TailTextArea',
          //   placeholder: this.$t('form.payment_details'),
          //   model: 'payment',
          //   styleClasses: 'w-full px-3 -mb-3',
          //   maxLength: 255,
          //   required: false
          // }
        ]
      },
      schema: {
        fields: [
          {
            type: 'TailInput',
            placeholder: this.$t('form.firstName'),
            model: 'first_name',
            styleClasses: 'w-full px-3 -mb-3',
            required: true,
            validator: VueFormGenerator.validators.required.locale({
              fieldIsRequired: this.$t('form.required')
            })
          },
          {
            type: 'TailInput',
            placeholder: this.$t('form.lastName'),
            model: 'last_name',
            styleClasses: 'w-full px-3 -mb-3',
            required: true,
            validator: VueFormGenerator.validators.required.locale({
              fieldIsRequired: this.$t('form.required')
            })
          },
          {
            type: 'TailEmailInput',
            placeholder: this.$t('form.email'),
            model: 'email',
            inputType: 'email',
            styleClasses: 'w-full px-3 -mb-3',
            required: true,
            validator: VueFormGenerator.validators.required.locale({
              fieldIsRequired: this.$t('form.required')
            })
          },
          {
            type: 'TailPhoneInput',
            placeholder: this.$t('form.phone'),
            model: 'phone',
            maxLength: 255,
            required: this.isPhoneNumberRequired(),
            styleClasses: 'w-full px-3 -mb-3',
            validator: VueFormGenerator.validators.required.locale({
              fieldIsRequired: this.$t('form.required')
            })
          },
          {
            type: 'TailInput',
            placeholder: this.data.settingWidget.custom_field_input_required
              ? this.data.settingWidget.custom_field_input + '*'
              : this.data.settingWidget.custom_field_input,
            model: 'customField',
            maxLength: 255,
            styleClasses: 'w-full px-3 -mb-2',
            required: () => {
              return this.data.settingWidget.custom_field_input_required
            },
            visible: () => {
              return this.data.settingWidget.custom_field_input
            }
          },
          {
            type: 'TailSelect',
            label: null,
            model: 'occasion',
            visible: this.data.occasionTags.length > 0,
            styleClasses: 'w-full px-3 -mb-3 -mt-1 text-gray-400',
            values: [
              {
                id: null,
                name: this.$t('form.choose_occasion')
              }
            ].concat(this.data.occasionTags)
          },
          {
            type: 'TailTextArea',
            placeholder: this.$t('form.comment'),
            model: 'notice',
            maxLength: 255,
            styleClasses: 'w-full px-3 -mb-2'
          },
          {
            type: 'TailCheckbox',
            title: this.$t('form.newsletter'),
            styleClasses: 'w-full px-3 mt-1 -mb-3',
            default: false,
            visible: false,
            model: 'newsletter_optin'
          },
          {
            type: 'TailCheckbox',
            title: this.$t('form.gdpr'),
            styleClasses: 'w-full px-3 -mb-3',
            model: 'gdpr',
            default: false,
            required: true,
            validator: [this.isGdprChecked]
          }
        ]
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: false
      }
    }
  },
  watch: {
    '$parent.active'() {
      if (this.$parent.active) {
        this.$emit('set-visibility-btn', true)
      }
    }
  },
  computed: {
    dateReadFormat() {
      return moment(this.model.reservation.date).locale(language).format('DD.MM.YYYY')
    },
    timeReadFormat() {
      return this.model.reservation.time
    },
    persons() {
      return this.model.reservation.adults + this.model.reservation.children
    }
  },
  methods: {
    toggleCompanyData() {
      this.showCompanyInput = !this.showCompanyInput
      this.$emit('set-visibility-btn', !this.showCompanyInput)
      this.$emit('set-visibility-back-btn', !this.showCompanyInput)

      // set country by IP if nothing is set
      if (!this.scopeModel.country) {
        axios.get('/api/get-ip').then((payload) => {
          this.scopeModel.country = payload.data.country
        })
      }
    },
    isPhoneNumberRequired() {
      if (this.data && this.data.settingWidget && this.data.settingWidget.phoneOptional == true) {
        return false
      }

      return true
    },
    isGdprChecked() {
      if (this.scopeModel.gdpr == true) {
        return []
      }

      return [this.$t('form.required')]
    },
    setCustomer() {
      this.$emit('set-customer', this.scopeModel)
    },
    validate() {
      //this.$refs.form.validate()
    }
  },
  mounted() {
    this.$root.$on('submit-form', this.validate)

    if (this.data && this.data.settingWidget && this.data.settingWidget.phoneOptional == true) {
      this.scopeModel.phoneIsRequired = false
    }
  },
  components: {
    BtnPrimary,
    WidgetHeader
  }
}
</script>
