<template>
  <!-- TODO theme by param -->
  <div class="theme-green">
    <div
      v-if="data"
      class="border relative mx-auto flex flex-col overflow-hidden rounded-lg border-gray-200 bg-white p-5"
      style="width: 100%; max-width: 420px; height: 550px"
    >
      <div
        v-if="loading"
        class="fadeIn animated faster absolute inset-0 z-30 flex items-center justify-center bg-white"
      >
        <i class="fad fa-spin fa-spinner-third text-3xl"></i>
      </div>
      <div
        v-if="loadingMenu"
        class="absolute inset-0 z-30 flex items-center justify-center bg-white"
      >
        <i class="fad fa-spin fa-spinner-third text-3xl"></i>
      </div>
      <reservation-error v-if="errorOccurred" />
      <no-table-available v-if="noTableAvailable" />
      <reservation-done
        :share-links="shareLinks"
        :show-inquiry-note="!data.settingReservation.direct_submit"
        :model="model"
        :data="data"
        :url="url"
        v-if="reservationDone && hasDeposit == false"
      />
      <reservation-deposit
        :session-id="sessionId"
        :data="data"
        v-if="reservationDone && hasDeposit == true"
      />
      <div v-if="!reservationDone && !errorOccurred && !noTableAvailable">
        <form-wizard ref="wizard" @on-change="onChange">
          <tab-content
            v-for="tab in tabs"
            :key="tab.component"
            :title="tab.component"
          >
            <component
              :is="tab.component"
              :data="data"
              :goes-forward="goesForward"
              :show-children="data.settingWidget.children"
              :menu-data="menuData"
              :model="model"
              @set-locale="setLocale"
              @reset-menu="resetMenu"
              @fetch-menu="getMenu"
              @toggle-offer="toggleOffer"
              @set-visibility-btn="setVisibilityNextBtn"
              @set-visibility-back-btn="setVisibilityBackBtn"
              @set-date="setDate"
              @set-time="setTime"
              @set-duration="setDuration"
              @remove-persons="removePersons"
              @add-persons="addPersons"
              @remove-children="removeChildren"
              @add-children="addChildren"
              @select-dish="selectDish"
              @select-standard="selectStandardMenu"
              @select-special="selectSpecialMenu"
              @select-room="selectLounge"
              @set-customer="setCustomer"
              @menu-persons-changed="changedMenuPersons"
            ></component>
          </tab-content>
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left">
              <btn-second
                class="mr-1"
                v-if="props.activeTabIndex > 0 && !hideBackBtn"
                @click.native="props.prevTab()"
                :style="props.fillButtonStyle"
                data-cypress="btn-prev"
                :title="$t('btn.previous')"
              />
            </div>
            <div v-if="!hideNextBtn" class="wizard-footer-right">
              <btn-primary
                v-if="!props.isLastStep"
                @click.native="props.nextTab()"
                class="wizard-footer-right ml-1"
                :style="props.fillButtonStyle"
                data-cypress="btn-next"
                :title="$t('btn.next')"
              />
              <btn-primary
                v-else
                @click.native="sendToServer"
                dusk="submit"
                class="wizard-footer-right finish-button ml-1"
                :style="props.fillButtonStyle"
                :disabled="loading"
                data-cypress="btn-submit"
                :title="$t('btn.reservation')"
              />
            </div>
          </template>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import BtnSecond from "../components/Button/Second";
import BtnPrimary from "../components/Button/Primary";
import DateStep from "../steps/Date";
import TimeStep from "../steps/Time";
import TimePersons from "../steps/Persons";
import CustomerStep from "../steps/Customer";
import ReservationDone from "../steps/Done";
import ReservationDeposit from "../steps/Deposit";
import axios from "axios";
import LoungeStep from "../steps/Lounge";
import AlertWarning from "../components/Alert/Warning";
import ReservationError from "../steps/Error";
import MenuStep from "../steps/Menu";
import NoTableAvailable from "../steps/NoTableAvailable/Index";

export default {
  name: "ReservationWidget",
  components: {
    MenuStep,
    ReservationError,
    AlertWarning,
    LoungeStep,
    CustomerStep,
    ReservationDone,
    ReservationDeposit,
    TimePersons,
    TimeStep,
    DateStep,
    BtnSecond,
    BtnPrimary,
    FormWizard,
    NoTableAvailable,
    TabContent,
  },
  data() {
    return {
      goesForward: true,
      data: null,
      tabs: [
        {
          component: "date-step",
          hide: false,
        },
        {
          component: "time-persons",
          hide: false,
        },
        {
          component: "time-step",
          hide: false,
        },
        {
          component: "menu-step",
          hide: false,
        },
        {
          component: "lounge-step",
          hide: false,
        },
        {
          component: "customer-step",
          hide: false,
        },
      ],
      hasDeposit: false,
      sessionId: null,
      reservationDone: false,
      errorOccurred: false,
      loading: false,
      locale: null,
      loadingMenu: false,
      apikey: null,
      url: null,
      shareLinks: {},
      hideBackBtn: false,
      hideNextBtn: true,
      noTableAvailable: false,
      errors: {},
      menuData: {
        preOrders: [],
        specialMenu: [],
      },
      model: {
        reservation: {
          offer: false,
          date: null,
          adults: 2,
          children: 0,
          time: null,
          wished_lounge_id: null,
          room_group_id: null,
          menu_special_id: null,
          menu_persons: 0,
          preOrders: [],
        },
        customer: {
          first_name: null,
          last_name: null,
          email: null,
          phone: "",
          notice: null,
        },
      },
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_ENDPOINT +
          "/api-widget/reservation/" +
          this.$route.params.apikey +
          "?api"
      )
      .then((data) => {
        this.data = data.data;
        this.apikey = this.data.user.apikey;
      });

    this.$root.$on("reload-menu", (val) => {
      this.reloadMenu(val);
    });

    this.$root.$on("wizard-next-tab", () => {
      this.$refs.wizard.nextTab();
    });

    this.$root.$on("wizard-prev-tab", () => {
      this.$refs.wizard.prevTab();
    });
  },

  watch: {
    "model.reservation.adults": function (newValue, oldValue) {
      if (
        oldValue + this.model.reservation.children ===
        this.model.reservation.menu_persons
      ) {
        this.model.reservation.menu_persons =
          newValue + this.model.reservation.children;
        return;
      }

      if (oldValue === this.model.reservation.menu_persons) {
        this.model.reservation.menu_persons = newValue;
      }
    },
    "model.reservation.children": function (newValue, oldValue) {
      if (this.menuData.hasChildren == true && oldValue == 0) {
        return;
      }
      // when children gets removed also child menu gets removed
      if (newValue == 0) {
        this.model.reservation.preOrders =
          this.model.reservation.preOrders.filter(
            (dish) => dish.category.children == false
          );
      }

      if (
        oldValue + this.model.reservation.adults ===
        this.model.reservation.menu_persons
      ) {
        this.model.reservation.menu_persons =
          newValue + this.model.reservation.adults;
      }
    },
    "model.reservation.menu_persons": function (newValue, oldValue) {
      this.updatePreOrderAmount(newValue, oldValue, true);
    },
  },
  methods: {
    resetMenu() {
      this.model.reservation.preOrders = [];
      this.model.reservation.menu_special_id = null;
    },
    setLocale(locale) {
      this.locale = locale;
    },
    onChange(prev, next) {
      this.goesForward = prev <= next;

      if (
        prev == 4 &&
        next == 3 &&
        this.menuData.preOrders.length == 0 &&
        this.menuData.specialMenu.length == 0
      ) {
        this.$refs.wizard.changeTab(3, 2);
      }
    },
    changedMenuPersons(val) {
      this.model.reservation.menu_persons = val;
    },
    toggleOffer(val) {
      this.model.reservation.offer = val;
    },
    updatePreOrderAmount(newValue) {
      let test = this.model.reservation.preOrders;

      test.map((preOrder) => {
        if (!preOrder.category.multiple) {
          preOrder.amount = newValue;
        }

        return preOrder;
      });

      this.model.reservation.preOrders = test;

      this.model.reservation.preOrders =
        this.model.reservation.preOrders.filter((dish) => dish.id !== 1);
    },
    selectLounge(payload) {
      if (payload.type === "roomGroup") {
        this.model.reservation.room_group_id = payload.id;
      } else {
        this.model.reservation.wished_lounge_id = payload.id;
      }

      this.$refs.wizard.nextTab();
    },
    selectStandardMenu() {
      this.model.reservation.preOrders = [];
      this.model.reservation.menu_special_id = null;
      this.$refs.wizard.nextTab();
    },
    selectSpecialMenu(id) {
      this.model.reservation.preOrders = [];
      this.model.reservation.menu_special_id = id;
      this.$refs.wizard.nextTab();
    },
    setVisibilityNextBtn(value) {
      this.hideNextBtn = !value;
    },
    setVisibilityBackBtn(value) {
      this.hideBackBtn = !value;
    },
    isPhoneRequired() {
      if (
        this.data &&
        this.data.settingWidget &&
        this.data.settingWidget.phoneOptional == true
      ) {
        return false;
      }

      return true;
    },
    sendToServer() {
      this.$root.$emit("submit-form");
      this.loading = true;
      const phoneNumberOptional = !this.isPhoneRequired();
      this.model.customer.locale = this.locale;
      let customer = { ...this.model.customer };

      axios
        .post(this.data.postUrl, {
          source: this.source,
          reservation: this.model.reservation,
          customer: customer,
          phoneOptional: phoneNumberOptional,
        })
        .then((response) => {
          this.loading = false;
          if (response.data[0] == "no_table") {
            this.handleNoTableResponse();
            return;
          }

          if (response.data[0] == "error") {
            this.handleErrorResponse();
            return;
          }

          this.handleSuccessResponse(response);
        })
        .catch((error) => {
          this.loading = false;
          this.reservationDone = false;
          this.errorOccurred = false;
          this.errors = error.response.data;
        });
    },
    handleErrorResponse() {
      this.errorOccurred = true;
      this.reservationDone = false;
      this.noTableAvailable = false;
    },
    handleSuccessResponse(response) {
      this.sessionId = response.data.sessionId;
      this.hasDeposit = response.data.hasDeposit;
      this.shareLinks = response.data.shareLinks;
      this.url = response.data.url;
      this.reservationDone = true;
      this.errorOccurred = false;
    },
    handleNoTableResponse() {
      this.noTableAvailable = true;
      this.errorOccurred = false;
      this.reservationDone = false;
    },
    setCustomer(customer) {
      this.model.customer = customer;
    },
    removePersons() {
      this.model.reservation.adults--;
    },
    addPersons() {
      this.model.reservation.adults++;
    },
    removeChildren() {
      this.model.reservation.children--;
    },
    addChildren() {
      this.model.reservation.children++;
    },
    setTime(time) {
      this.model.reservation.time = time.time;
      this.model.reservation.preOrders = [];
      this.model.reservation.menu_special_id = null;
      this.$refs.wizard.nextTab();
    },
    setDuration(duration) {
      this.model.reservation.duration = duration;
    },
    setDate(date) {
      this.model.reservation.date = date;
      this.model.reservation.preOrders = [];
      this.model.reservation.menu_special_id = null;
      this.$refs.wizard.nextTab();
    },
    getApiKey() {
      return this.apikey;
    },
    getMenu() {
      this.loadingMenu = true;

      axios
        .get(
          process.env.VUE_APP_ENDPOINT +
            `/api-widget/reservation/${this.apikey}/menu`,
          {
            params: {
              date: this.model.reservation.date,
              time: this.model.reservation.time,
              adults: this.model.reservation.adults,
              children: this.model.reservation.children,
            },
          }
        )
        .then((response) => {
          this.menuData = response.data;
          this.loadingMenu = false;
          const menuIsHidden =
            this.menuData.preOrders.length == 0 &&
            this.menuData.specialMenu.length == 0;
          if (menuIsHidden == true && this.goesForward) {
            this.$refs.wizard.nextTab();
          }
          if (menuIsHidden == true && this.goesForward == false) {
            this.$refs.wizard.prevTab();
          }
        });
    },
    reloadMenu(val) {
      this.loading = true;

      axios
        .get(
          process.env.VUE_APP_ENDPOINT +
            `/api-widget/reservation/${this.apikey}/menu`,
          {
            params: {
              date: this.model.reservation.date,
              time: this.model.reservation.time,
              adults: val,
              children: this.model.reservation.children,
            },
          }
        )
        .then((response) => {
          this.menuData = response.data;
          let categoriesIds = [];
          this.menuData.preOrders.map((preOrder) => {
            categoriesIds.push(preOrder.id);
          });
          this.model.reservation.preOrders =
            this.model.reservation.preOrders.filter((preOrder) =>
              categoriesIds.includes(preOrder.category_id)
            );
          this.loading = false;
        });
    },
    selectDish(dish) {
      this.model.reservation.menu_special_id = null;
      const index = this.model.reservation.preOrders.findIndex(
        (o) => o.id == dish.id
      );

      if (index >= 0) {
        this.model.reservation.preOrders.splice(index, 1);
      } else {
        if (dish.category.multiple == true) {
          this.model.reservation.preOrders.push(dish);
        } else {
          this.model.reservation.preOrders =
            this.model.reservation.preOrders.filter((preOrder) => {
              return preOrder.category_id !== dish.category_id;
            });

          this.model.reservation.preOrders.push(dish);
        }
      }
    },
  },
};
</script>
