<template>
  <div id="menu-scrolling" class="scrolling-touch relative overflow-auto" style="height: 23rem">
    <div v-if="nothingMultiple == true" class="mb-0 rounded-md shadow-sm">
      <select
        v-model="menuPersons"
        class="transition form-select block w-full text-sm duration-150 ease-in-out sm:leading-5"
        @change="menuPersonsChanged"
      >
        <option v-for="persons in menuPersonValues" :key="persons.id" :value="persons.id">
          {{ persons.name }}
        </option>
      </select>
    </div>

    <menu-pre-order-category
      v-for="category in menuData"
      ref="menuPreOrderCategory"
      :key="category.id"
      :pre-order-is-required="preOrderIsRequired"
      :category-amount="menuData.length"
      :category="category"
      :model="model"
      :has-child-menu="hasChildMenu"
      @select-dish="selectDish"
    />

    <alert-info v-if="menuData.length == 0" class="mt-3" :text="$t('no_preorder_available')"></alert-info>

    <div v-if="data.settingReservation.offer" class="mb-1 ml-1 mt-4">
      <div class="flex items-start">
        <div class="flex h-5 items-center">
          <input
            id="offer"
            v-model="offer"
            type="checkbox"
            data-cypress="select-preoder-offer"
            class="transition form-checkbox h-4 w-4 text-purple-600 duration-150 ease-in-out"
            @change="toggleOffer"
          />
        </div>
        <div class="ml-3 text-xs leading-5">
          <label for="offer" class="font-medium text-gray-700">{{ $t('i_want_an_offer') }}</label>
        </div>
      </div>
    </div>
    <div
      v-if="!preOrderIsRequired"
      class="cursor-pointer pt-3 text-center text-xs font-medium text-gray-400 hover:text-gray-800"
      @click="reset"
    >
      {{ $t('btn.remove_preOrder') }}
    </div>
  </div>
</template>

<script>
import MenuPreOrderCategory from './Category'
import AlertInfo from '../../../components/Alert/Info'

export default {
  name: 'MenuPreOrder',
  components: { AlertInfo, MenuPreOrderCategory },
  props: {
    hasChildMenu: {
      type: Boolean,
      default: false
    },
    nothingMultiple: {
      type: Boolean,
      default: false
    },
    preOrderIsRequired: {
      type: Boolean,
      default: false
    },
    menuData: {
      type: Array,
      default: () => []
    },
    model: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      offer: false,
      menuPersons: 0
    }
  },
  computed: {
    menuPersonValues() {
      let amount = []

      for (let i = 1; i < this.model.reservation.adults + this.model.reservation.children + 1; i++) {
        amount.push({
          id: i,
          name: this.$t('menu_persons', { amount: i })
        })
      }

      return amount
    }
  },
  watch: {
    menuData: function () {
      if (this.menuData.length == 1 && this.$refs.menuPreOrderCategory) {
        this.$refs.menuPreOrderCategory[0].show = true
      }
    },
    'model.reservation.menu_persons': function () {
      this.menuPersons = this.model.reservation.menu_persons
      this.menuPersonsChanged()
    }
  },
  mounted() {
    if (this.hasChildMenu) {
      this.menuPersons = this.model.reservation.adults
    } else {
      this.menuPersons = this.model.reservation.children + this.model.reservation.adults
    }
    this.menuPersonsChanged()
  },
  methods: {
    updatePreOrderAmount(newValue) {
      let test = this.model.reservation.preOrders

      test.map((preOrder) => {
        if (!preOrder.category.multiple) {
          preOrder.amount = newValue
        }

        return preOrder
      })

      // eslint-disable-next-line
      this.model.reservation.preOrders = test

      // eslint-disable-next-line
      this.model.reservation.preOrders = this.model.reservation.preOrders.filter((dish) => dish.id !== 1)
    },
    menuPersonsChanged() {
      this.$emit('menu-persons-changed', this.menuPersons)
    },
    toggleOffer() {
      this.$emit('toggle-offer', this.offer)
    },
    reset() {
      this.$emit('reset')
    },
    selectDish(dish) {
      this.$emit('select-dish', dish)
    }
  }
}
</script>
